<template>
  <div class="columns is-centered ml-5 mr-5 mb-4">
    <div class="column is-half">
      <div class="notification is-danger is-light" v-if="error">
        <button class="delete" v-on:click="error = ''"></button>
        An error occurred during image processing.
        <br>
        Please, try again with another image.
      </div>
      <picture-input
        ref="pictureInput"
        width="600"
        height="600"
        :crop="false"
        margin="4"
        accept="image/jpeg,image/png"
        size="10"
        :hide-change-button="true"
        :removable="true"
        remove-button-class="button"
        :custom-strings="{
          upload: '<p>Your device does not support file uploading.</p>',
          drag: 'Drag an image file <br>or click here to select one',
          remove: 'Remove image'
        }"
        @remove="resetData"
        @click="resetData"
        @change="setImage"
        :hidden="processing">
      </picture-input>
      <div class="sticky-image" v-if="processing && svgViewBox">
        <svg
          width="100%"
          height="100%"
          :viewBox="svgViewBox"
          xmlns="http://www.w3.org/2000/svg">
          <image :href="image" width="100%" height="100%" preserveAspectRatio/>
          <polygon
            v-for="(result, index) in transcriptions"
            :key="index"
            :points="polygonPoints(result.polygon)"
            :stroke="index == selectedResult ? 'red' : 'blue'"
            stroke-opacity="0.4"
            :fill="index == selectedResult ? 'red' : 'blue'"
            fill-opacity="0.2"
            class="is-clickable"
            v-on:click="selectedResult = index"
          />
        </svg>
        <button v-if="!loading" class="button is-danger mt-4" v-on:click="retry">Change image</button>
      </div>
    </div>
    <div class="column is-half">

      <form class="mb-4" v-on:submit.prevent="generateResults">
        <div class="field is-horizontal mb-1">
          <div class="field-label is-normal"><label class="label">Document type</label></div>
          <div class="field-body">
            <div class="control">
              <div class="field">
                <div class="select">
                  <select v-model="languageCode">
                    <option value="atr" v-if="enableATR">Generic Automatic Text Recognition</option>
                    <option value="fra" v-if="languages.includes('fra')">French Automatic Text Recognition (full page)</option>
                    <option value="eng" v-if="languages.includes('eng')">English Automatic Text Recognition (full page)</option>
                    <option value="arb" v-if="languages.includes('arb')">Arabic Automatic Text Recognition (full page)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal mb-1">
          <div class="field-label is-normal"><label class="label">E-mail address</label></div>
          <div class="field-body">
            <div class="control">
              <div class="field">
                <input v-model="email" class="input" type="email" required maxlength="150" size="32" placeholder="Contact email…" />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"><label class="label"></label></div>
          <div class="field-body">
            <div class="control">
              <div class="field">
                <button type="submit" class="button is-info">Recognize text</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />

      <template v-if="!processing">
        <div class="message mx-6">
          <div class="message-body">
            Text lines and their associated transcriptions will be displayed here.
          </div>
        </div>
      </template>
      <template v-else-if="loading">
        <div class="message is-info">
          <div class="message-body">
            <div class="is-flex is-justify-content-center">
              <div class="loader is-size-4 mb-3 mt-1"></div>
            </div>
            Image processing in progress
          </div>
        </div>
      </template>
      <div v-if="results && transcriptions.length">
        <h1 class="title is-4 has-text-info-dark">
          Document recognition results
          <span class="subtitle is-7 has-text-weight-light">(generated in {{ results.execution_time }}s)</span>
        </h1>
        <table class="table is-fullwidth  is-hoverable">
          <tbody>
            <tr
              v-for="(result, index) in transcriptions"
              :key="index"
              :class="{ 'is-selected': index == selectedResult }"
              v-on:click="selectedResult = index"
            >
              <td class="is-clickable" :class="[languageCode === 'arb' ? 'has-text-right' : 'has-text-left']">{{ result.text }}</td>
            </tr>
          </tbody>
        </table>
        <button class="button is-small is-pulled-left" v-on:click="displayJSON = !displayJSON">{{ !displayJSON ? "Display raw JSON" : "Hide raw JSON" }}</button>
        <br>
        <json-viewer
          :value="results"
          :expand-depth="3"
          :copyable="{copyText: 'Copy JSON', copiedText: 'JSON copied', timeout: 2000}"
          boxed
          class="mt-4 has-text-left"
          v-if="displayJSON">
        </json-viewer>
      </div>
      <div v-else-if="results" class="notification is-warning is-light">
        No line found
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import PictureInput from 'vue-picture-input'
import { API_URL, API_TOKEN, LANGUAGES, ATR_API_URL, ATR_API_TOKEN } from '../main'

export default {
  data: () => ({
    languageCode: "",
    loading: false,
    processing: false,
    image: "",
    email: "",
    results: "",
    selectedResult: "",
    error: "",
    svgViewBox: "",
    displayJSON: false
  }),
  components: {
    JsonViewer,
    PictureInput
  },
  mounted () {
    if (this.enableATR) {
      this.languageCode = "atr"
    } else {
      this.languageCode = this.languages[0]
    }
  },
  methods: {
    polygonPoints (points) {
      let output = ""
      points.forEach(point => {
        output += point[0] + "," + point[1] + " "
      })
      return output.trim()
    },
    resetData () {
      this.results = ""
      this.selectedResult = ""
      this.error = ""
      this.displayJSON = false
    },
    retry () {
      this.resetData()
      this.$refs.pictureInput.removeImage()
      this.processing = false
    },
    setImage (imageDataURI) {
      this.image = imageDataURI
    },
    fileFromDataURI (dataURI) {
      let URISplit = dataURI.split(',')
      const mime = URISplit[0].split(":")[1].split(";")[0]
      const ext = mime.split("/")[1]
      const imageBuffer = Buffer.from(URISplit[1], "base64")
      return new File([imageBuffer], "image." + ext, { "type": mime })
    },
    buildAPIRequest (imageDataURI) {
      let formData = new FormData()
      formData.append("image", this.fileFromDataURI(imageDataURI))
      formData.append("email", this.email)

      const headers = new Headers()
      let baseURL

      if (this.languageCode == "atr") {
        console.log("Sending image to the ATR Ocelus API")
        headers.append("API-Key", ATR_API_TOKEN)
        baseURL = ATR_API_URL + "/transcribe/"
      } else {
        console.log("Sending image to Ocelus API")
        headers.append("API-Key", API_TOKEN)
        baseURL = API_URL + "/transcribe/" + this.languageCode
      }

      return new Request(
        baseURL,
        {
          method: "POST",
          mode: "cors",
          headers,
          body: formData
        }
      )
    },
    async generateResults () {
      if (!this.image) {
        this.error = "No image"
        return
      }
      this.processing = true
      this.resetData()
      this.loading = true

      const request = this.buildAPIRequest(this.image)

      try {
        const res = await fetch(request)
        if (res.status != 200) {
          throw res.json()
        }
        this.results = await res.json()
      } catch (err) {
        this.error = err
        this.processing = false
      } finally {
        this.loading = false
      }
    },
    async loadImage (src) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    }
  },
  computed: {
    enableATR () {
      return ATR_API_URL && ATR_API_TOKEN
    },
    languages () {
      return LANGUAGES.split(',')
    },
    transcriptions () {
      if (!this.results?.results) return []
      return this.results.results
    }
  },
  watch: {
    image: async function (newValue, oldValue) {
      if (newValue == oldValue) return
      const img = await this.loadImage(newValue)
      this.svgViewBox = "0 0 " + img.width + " " + img.height
    }
  }
}
</script>

<style scoped>
.sticky-image{
  position: sticky;
  display: inline-block;
  top: 80px;
}
/* Prevent table rows to collapse in height when they're empty */
td:empty::after{
  content: "\00a0";
}
.table td {
  border: none;
}
.table th {
  border: none;
}
</style>
