import './main.scss'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'

// Build an object from all <meta> tags
const metas = (typeof document !== 'undefined' && document)
  ? [...document.getElementsByTagName('meta')].reduce((obj, meta) => { obj[meta.name] = meta.content; return obj }, {})
  : []
export const API_URL = metas.api_url || "http://localhost:8000/api/v1"
export const API_TOKEN = metas.api_token || "Teklia12345"

// Control if the teklia branding is displayed or not
export const BRAND = metas.brand || "teklia"

// Control which languages are available on this instance
export const LANGUAGES = metas.languages || "fra,eng,arb"

// Optional ATR Ocelus configuration. If set in metadata, this OCR will be selected by default
export const ATR_API_URL = metas?.["atr-api-url"] || null
export const ATR_API_TOKEN = metas?.["atr-api-token"] || null

Vue.use(Vuex)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
