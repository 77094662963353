<template>
  <div id="app">
    <img v-if="brand == 'teklia'" width="100px" alt="Teklia logo" src="https://assets.teklia.com/ocelus-demo/logo.png">
    <h1 class="title is-1 has-text-grey-dark is-family-sans-serif"> Ocelus API</h1>
    <h2 class="title is-2 has-text-grey-dark is-family-sans-serif">Try our handwritten document recognition engine</h2>
    <p  class="has-text-grey-dark is-family-sans-serif">This demo engine was trained on full page letters, it is not suited for part of pages, forms, fields, single text lines, etc.</p>
    <hr>
    <ImageUploader />
  </div>
</template>

<script>
import ImageUploader from './components/ImageUploader.vue'
import { BRAND } from './main'

export default {
  name: 'App',
  data: () => ({
    brand: BRAND,
  }),
  components: {
    ImageUploader
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
</style>
